.playcontainer {
    color: #ffffff;

    .score {
        position: fixed;
        top: 0;
        right: 0;
        margin: 10px 20px 0 0;
        font-size: 8rem;

        @media (max-width: 900px) {
            font-size: 4rem;
        }

    }

    .main {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30vh;
        flex-wrap: wrap;
        text-align: center;
        margin-top: 10vh;
        font-size: 2em;

        @media(max-width: 900px) {
            font-size: 0.9em;
        }

        div {
            width: 100%;
        }

        .kana {
            font-size: 1em;
        }

        .kanji {
            font-size: 3em;
        }

        .meaning {
            font-size: 1em;
        }
    }

    .options {

        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: space-around;
        width: 100%;
        align-items: baseline;
        flex-wrap: wrap;

        >img {
            &.correct {
                cursor: default;

                &:hover {
                    opacity: 1;
                }
            }

            &.incorrect {
                cursor: default;
                opacity: 0.2;

                &:hover {
                    opacity: 0.2;
                }
            }

            &:hover {

                opacity: 0.7;
            }

            cursor: pointer;
            max-width: 15vw;

            @media (max-width: 600px) {
                width: 30vw;
                max-width: none;
            }

            max-height: 400px;
        }
    }
}