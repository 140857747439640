.rulescontainer {
  color: #ffffff;
  font-size: 2rem;
  top: 0;
  left: 0;
  width: 100vw;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  pointer-events: none;
  @media (max-width: 900px) {
    font-size: 1.6rem;
  }
  @media (max-width: 500px) {
    font-size: 1.4rem;
  }
}
