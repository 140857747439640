.buttons {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 85vh;

    >img {
        z-index: 5;
        max-width: 180px;
        margin: 60px;
        cursor: pointer;

        @media (max-width: 900px) {
            max-width: 20%;
            margin: 40px;
        }

        @media (max-width: 500px) {
            margin: 20px;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    .rules {
        margin-top: 20rem;

        @media (max-width: 900px) {
            margin-top: 13rem;
        }

        @media (max-width: 500px) {
            margin-top: 7rem;
        }
    }

    .credits {
        margin-top: -10rem;

        @media (max-width: 900px) {
            margin-top: -7.5rem;
        }

        @media (max-width: 500px) {
            margin-top: -4rem;
        }
    }
}