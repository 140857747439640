@import './styles/variables.scss';

body {
  font-family: 'Open sans', sans-serif;
  color: $text-primary;
  min-height: 100%;

  .bg-container {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -10;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ee5235+0,cc3595+20,cc3595+80,785eb7+100 */
    background: #ee5235;
    /* Old browsers */
    background: -moz-linear-gradient(top,
        #ee5235 0%,
        #cc3595 20%,
        #cc3595 80%,
        #785eb7 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top,
        #ee5235 0%,
        #cc3595 20%,
        #cc3595 80%,
        #785eb7 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,
        #ee5235 0%,
        #cc3595 20%,
        #cc3595 80%,
        #785eb7 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ee5235', endColorstr='#785eb7', GradientType=0);

    .bg {
      width: 100%;
      height: 100%;
      position: fixed;

      background-size: 25px 25px;
      $bg_color: rgba(0, 0, 0, 0.3);
      opacity: 0.4;
      background-image: linear-gradient(to right, $bg_color 1px, transparent 1px),
        linear-gradient(to bottom, $bg_color 1px, transparent 1px);
      pointer-events: none;
    }

  }

  /* IE6-9 */
  a {
    font-weight: 800;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  p {
    font-weight: 400;

    a {
      font-weight: 500;

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    color: $secondary-blue;
  }

  button.btn {
    font-family: 'Oswald', sans-serif;
    font-weight: 800;
    font-size: 1.2em;
    border-radius: 40px;
    border: none;
    padding: 10px 15px;
    margin: 10px;

    &,
    &:hover,
    &:active,
    &:focus {
      outline: none;
      color: $text-header-primary;
    }
  }

  input,
  select {
    border: #cccccc 2px solid;
    margin: 10px;
    padding: 7px 15px;
    color: $text-primary;

    &:focus {
      outline: none;
      outline: 0;
    }
  }

  input {
    border-radius: 30px;
    max-width: 100%;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 10px;
    padding-right: 30px;
    cursor: pointer;

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
  }

  hr {
    border-top: $secondary-blue 1px solid;
  }

  .boxcontainer {
    position: fixed;
    top: 10vh;
    height: 80vh;
    width: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    width: 100%;

    .box {
      width: 100%;
      max-width: 100rem;
    }
  }

  .title-image {
    max-width: 40%;
    max-height: 130px;
    cursor: pointer;
  }

  .dolphin {
    position: fixed;
    bottom: 0;
    max-width: 80%;
    right: 0;
    max-height: 170px;
  }
}